import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, FormsModule],
   
  schemas: [CUSTOM_ELEMENTS_SCHEMA]  
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor() {}
}
